



















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TrashIconAlt extends Vue {
  @Prop({ default: '#4D4D4D' }) private fill!: string
}
