import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { WhiteSellerPresenter } from '@/app/ui/presenters/WhiteSellerPresenter'
import {
  CreateWhiteSellerApiRequest,
  DeleteWhiteSellerApiRequest,
  UpdateWhiteSellerApiRequest
} from '@/data/payload/api/WhiteSellerRequest'
import { Utils } from '@/app/infrastructures/misc'
import { WhiteListSeller, WhiteSeller } from '@/domain/entities/WhiteSeller'
import { Pagination } from '@/domain/entities/Pagination'

interface WhiteSellerPayload {
  sellerName: string
  sellerPhone: string
  status: string
  agentId?: number[]
  sellerRole: string
  codHandling?: string
}

export interface WhiteSellerState {
  isLoading: boolean
  dataWhiteListSeller: WhiteSeller[]
  dataWhiteSeller: WhiteSeller
  paginationData: Pagination
  isAddWhiteSellerSuccess: boolean
  isEditWhiteSellerSuccess: boolean
  isDeleteWhiteSellerSuccess: boolean
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'marketplace',
})
class WhiteSellerController extends VuexModule implements WhiteSellerState {
  private presenter: WhiteSellerPresenter = container.resolve(WhiteSellerPresenter)
  public isLoading = false
  public dataWhiteListSeller = [new WhiteSeller()]
  public dataWhiteSeller = new WhiteSeller()
  public paginationData = new Pagination()
  public isAddWhiteSellerSuccess = false
  public isEditWhiteSellerSuccess = false
  public isDeleteWhiteSellerSuccess = false

  @Action({ rawError: true })
  public getAll(params: Record<string, any>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then((res) => {
        if (res.data) this.setDataWhiteList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch White List Seller Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetail(id: string) {
    this.setLoading(true)

    this.presenter
      .get(id)
      .then((res) => {
        if (res) this.setDataWhiteSeller(res) 
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail White Seller Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public create(payload: WhiteSellerPayload) {
    this.setLoading(true)
    this.presenter
      .create(
        new CreateWhiteSellerApiRequest(
          payload.sellerName,
          payload.sellerPhone,
          payload.agentId,
          payload.sellerRole,
          payload.status,
          payload.codHandling
        )
      )
      .then((res) => {
        this.setIsAddWhiteSellerSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create White Seller Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsAddWhiteSellerSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateWhiteSeller(payload: {
    id: number,
    name: string,
    phone: string,
    status: string,
    agentId: number[],
    role: string,
    codHandling: string
  }) {
    this.setLoading(true)
    this.presenter
      .update(
        new UpdateWhiteSellerApiRequest(
          payload.id,
          payload.name,
          payload.phone,
          payload.status,
          payload.agentId,
          payload.role,
          payload.codHandling
        )
      )
      .then((res) => {
        this.setIsEditWhiteSellerSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update White Seller Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsEditWhiteSellerSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteWhiteSeller(payload: { ids: number[] }) {
    this.setLoading(true)

    this.presenter
      .delete(
        new DeleteWhiteSellerApiRequest(payload.ids)
      )
      .then(() => {
        this.setIsDeleteWhiteSellerSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete White Seller Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsDeleteWhiteSellerSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportWhiteListSeller() {
    this.setLoading(true)
    this.presenter
      .export()
      .then((res) => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export White List Seller Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setDataWhiteList(list: WhiteListSeller) {
    this.dataWhiteListSeller = <WhiteSeller[]>list.data
    this.paginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataWhiteSeller(list: WhiteSeller) {
    this.dataWhiteSeller = list
  }

  @Mutation
  public setIsAddWhiteSellerSuccess(bool: boolean) {
    this.isAddWhiteSellerSuccess = bool
  }

  @Mutation
  public setIsEditWhiteSellerSuccess(bool: boolean) {
    this.isEditWhiteSellerSuccess = bool
  }

  @Mutation
  public setIsDeleteWhiteSellerSuccess(bool: boolean) {
    this.isDeleteWhiteSellerSuccess = bool
  }
}

export default getModule(WhiteSellerController)
