import {
  CreateWhiteSellerRequestInterface,
  DeleteWhiteSellerRequestInterface,
  UpdateWhiteSellerRequestInterface
} from '@/data/payload/contracts/WhiteSellerRequest'

export class CreateWhiteSellerApiRequest implements CreateWhiteSellerRequestInterface {
  public sellerName?: string
  public sellerPhone?: string
  public agentId?: number[]
  public sellerRole?: string
  public status?: string
  public codHandling?: string

  constructor(
    sellerName?: string,
    sellerPhone?: string,
    agentId?: number[],
    sellerRole?: string,
    status?: string,
    codHandling?: string
  ) {
    this.sellerName = sellerName
    this.sellerPhone = sellerPhone
    this.agentId = agentId
    this.sellerRole = sellerRole
    this.status = status
    this.codHandling = codHandling
  }

  public toPayload(): string {
    const data = {
      seller_name: this.sellerName,
      seller_phone: this.sellerPhone,
      agent_id: this.agentId || undefined,
      seller_role: this.sellerRole,
      status: this.status,
      cod_handling: this.codHandling
    }
    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdateWhiteSellerApiRequest implements UpdateWhiteSellerRequestInterface {
  public id?: number
  public name?: string
  public phone?: string
  public status?: string
  public agentId?: number[]
  public role?: string
  public codHandling?: string

  constructor(
    id?: number,
    name?: string,
    phone?: string,
    status?: string,
    agentId?: number[],
    role?: string,
    codHandling?: string
  ) {
    this.id = id
    this.name = name
    this.phone = phone
    this.status = status
    this.agentId = agentId
    this.role = role
    this.codHandling = codHandling
  }

  public toPayload(): string {
    const data = {
      id: this.id,
      name: this.name,
      phone: this.phone,
      status: this.status,
      agent_id: this.agentId,
      role: this.role,
      cod_handling: this.codHandling
    }
    return JSON.stringify({
      ...data,
    })
  }
}

export class DeleteWhiteSellerApiRequest implements DeleteWhiteSellerRequestInterface {
  public ids?: number[]

  constructor(ids?: number[]) {
    this.ids = ids
  }

  public toPayload(): string {
    return JSON.stringify({ ids: this.ids })
  }
}
