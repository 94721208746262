























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import ModalDelete from '../components/MarketplaceModal/ModalDelete.vue'
import ModalSuccess from '../components/MarketplaceModal/ModalSuccess.vue'
import controller from '@/app/ui/controllers/WhiteSellerController'
import { WhiteSeller } from '@/domain/entities/WhiteSeller'
import { Utils } from '@/app/infrastructures/misc'
import { Location } from 'vue-router'

interface Params {
  page: number
  limit: number
  query: string
  status: string
}

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav,
    LoadingOverlay,
    TrashIcon,
    EditIcon,
    ModalDelete,
    ModalSuccess
  },
})
export default class ManageWhitelist extends Vue {
  controller = controller
  deleteModal = {
    show: false,
    ids: 0
  }
  successDeleteModal = false
  filterOptions = [
    { label: 'Semua', value: 'all' },
    { label: 'Aktif', value: 'active' },
    { label: 'Tidak Aktif', value: 'inactive' },
  ]
  parameters = {
    page: 1,
    limit: 50,
    query: '',
    status: this.filterOptions[0],
  }

  // Table
  headers = [
    'No.',
    'Nama Seller',
    'No. Telephone',
    'Status',
    'Partner POS',
    'Seller Role',
  ]
  whiteListSeller = Array<string[]>([])

  created(): void {
    this.fetchWhiteList()
  }

  get params(): Params {
    return {
      ...this.parameters,
      status: this.parameters.status.value,
      query: encodeURIComponent(this.parameters.query),
    }
  }

  private indexTable(idx: number): string {
    const getCountData = (this.parameters.page - 1) * this.parameters.limit
    return String(getCountData + idx + 1)
  }

  private fetchWhiteList(reset?: boolean) {
    if (reset) this.parameters.page = 1
    controller.getAll(this.params)
  }

  private onSearchWhiteSeller = Utils.debounce(() => {
    this.fetchWhiteList(true)
  }, 400)

  private onExport() {
    controller.exportWhiteListSeller()
  }

  private routeToAddSeller(): Location {
    return { name: 'AddSellerWhitelist' }
  }

  private routeTo(id: number, routeName: string): Location {
    return {
      name: routeName,
      params: { id: String(id) }
    }
  }

  private onDeleteModal(id: number) {
    this.deleteModal = {
      show: true,
      ids: id
    }
  }

  private onDelete() {
    controller.deleteWhiteSeller({ ids: [this.deleteModal.ids] })
  }

  private onCloseModalSuccess() {
    this.successDeleteModal = false
    controller.setIsDeleteWhiteSellerSuccess(false)
  }

  @Watch('controller.dataWhiteListSeller')
  setDataWhiteListSeller(data: WhiteSeller[]): void {
    const result = data.map((list) => {
      const partnerCode = list.partnerCode ? `[${list.partnerCode}]` : ''
      return [
        list.id,
        list.name,
        list.phone,
        list.status,
        `${list.partnerPOS || ''} ${partnerCode}`,
        list.role,
      ]
    })
    this.whiteListSeller = <Array<string[]>>result
  }

  @Watch('controller.isDeleteWhiteSellerSuccess')
  setDeleteWhiteSellerSuccess(data: boolean): void {
    this.deleteModal.show = false
    if (data) {
      this.fetchWhiteList(true)
      this.successDeleteModal = data
    }
  }

  beforeDestroy(): void {
    controller.setIsDeleteWhiteSellerSuccess(false)
  }
}
